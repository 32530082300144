import React, { useContext, useState } from "react";

import { FirebaseContext } from "../firebase";
import Slider from "react-slick";
require("firebase/storage");

const ImageLister = ({ images, size }) => {
    //console.log("images", images);

    const firebase = useContext(FirebaseContext);
    const [urls, setUrls] = useState([]);

    async function getImages(references) {
        const nUrls = [];

        for (let r of references) {
            await firebase
                .storage()
                .ref(r)
                .getDownloadURL()
                .then((url) => {
                    nUrls.push(url);
                });
        }
        return nUrls;
    }
    if (images.length > 0 && urls.length === 0) {
        //console.log("images to be had");
        const refs = [];
        if (images) {
            images.forEach((ref) => {
                // uploading with android generates whole storage references
                // which you cannot use here, but we can parse the correct URL from them.
                // example android ref: gs://asfaltti-beta.appspot.com/images/LgaH0z8GqBAXa3ghYvFW_1.jpg
                let s = ref.split(".com")[1];
                refs.push(s);
            });
        }

        let getImagePromise = new Promise((resolve, reject) => {
            const urls = getImages(refs);
            resolve(urls);
            reject(new Error("Something went wrong."));

            setTimeout(() => reject(new Error("Get image timeout")), 5000);
        });

        getImagePromise.then((result) => {
            //console.log("Image promise finished", result);

            if (urls.length == 0) {
                setUrls(result);
            }
        });
    }

    const settings = {
        dots: true,
    };

    return (
        <>
            {urls.map((item, index) => {
                return (
                    <a href={item} target="_blank" key={index}>
                        <img
                            src={item}
                            style={{
                                width: size ?? "140px",
                                height: size ?? "140px",
                            }}
                        />
                    </a>
                );
            })}
        </>
    );
};

export default ImageLister;
