import React from "react";

import ProgressBar from "./ProgressBar";

// map transaction state number to titles and values
const progressTitle = {
    1: "Luotu",
    1.1: "Ehdotus",
    1.15: "Hylätty",
    1.2: "Hyväksytty",
    2: "Työn alla",
    3: "Valmis",
};

const progressValue = {
    1: 0,
    1.1: 0.3,
    1.15: 0.3,
    1.2: 0.5,
    2: 0.7,
    3: 1,
};

const ProgressVisualizer = ({ transaction }) => {
    return (
        <div className="progress-visualizer">
            <p>Työn tila: {progressTitle[transaction.state]}</p>
            <ProgressBar
                completed={progressValue[transaction.state] * 100}
                bgcolor={"#ff6200"}
            />
        </div>
    );
};

export default ProgressVisualizer;
