import React, { useState, useEffect } from "react";
import "./App.css";

import Details from "./model/Details";

import Firebase, { FirebaseContext } from "./firebase";
require("firebase/auth");

function App() {
    const [user, setUser] = useState(null);

    useEffect(() => {
        if (!user) {
            console.log("no user, signing in");
            Firebase.auth()
                .signInAnonymously()
                .catch(function (error) {
                    console.log("error while signing in anonymously", error);
                });

            Firebase.auth().onAuthStateChanged(function (user) {
                if (user) {
                    console.log("user is signed in", user);
                    console.log("user id", user.uid);
                    setUser(user);
                } else {
                    console.log("user is signed out");
                    setUser(null);
                }
            });
        }
    }, [user]);

    if (user) {
        // show the actual application if the user is signed in
        return (
            <FirebaseContext.Provider value={Firebase}>
                <div className="App">
                    <Details />
                </div>
            </FirebaseContext.Provider>
        );
    } else {
        // show some loading placeholder here
        return (
            <div className="App">
                <p>Ladataan..</p>
            </div>
        );
    }
}

export default App;
