import React, { useContext } from "react";
import ImageLister from "./ImageLister";
import { offerCollectionPath } from "../CollectionPaths";
import { FirebaseContext } from "../firebase";
require("firebase/firestore");

const serviceMap = {
    CornerRockService: "Reunakivivauriot",
    PaintService: "Vauriot pihamerkinnöissä",
    RoadSignService: "Liikennemerkkivauriot",
    ManholeService: "Kansistot",
};

const DetailsOfferInfo = ({
    offer,
    visible,
    callback,
    notificationCallback,
}) => {
    //console.log("offer", offer);

    // get reference to firebase through the context
    const firebase = useContext(FirebaseContext);
    // get reference to firestore
    const db = firebase.firestore();

    const offerInteract = (e) => {
        console.log("offer choice", e);
        // update to database
        db.collection(offerCollectionPath)
            .doc(offer.key)
            .update({ accepted: e })
            .then(() => {
                console.log("offer updated successfully");
                // update accepted locally
                const newOffer = { ...offer };
                newOffer.accepted = e;
                callback(newOffer);
                // create notification
                notificationCallback("offer", e ? "hyväksytty" : "hylätty");
            })
            .catch((error) => {
                console.log("error while updating offer", error);
            });
    };

    return (
        <>
            <button
                className="collapse-button"
                data-toggle="collapse"
                data-target="#offer"
            >
                Tarjous
            </button>
            <div className={visible ? "collapse show" : "collapse"} id="offer">
                <div className="row-container first">
                    <div className="row row-small">
                        <p className="row-text gray">
                            Tarjous on hyväksyttävä 14 päivän aikana.
                        </p>
                    </div>
                    <div className="row">
                        <p className="row-text gray">Toimitusaika</p>
                        <p className="row-text orange">{offer?.deliveryDate}</p>
                    </div>
                    <div className="row">
                        <p className="row-text gray">Hinta</p>
                        <p className="row-text orange">{offer?.price} €</p>
                    </div>
                    <div className="row row-small">
                        <p className="row-text small gray">
                            * Hinnoittelu perustuu kuvien mukaisten vaurioiden
                            korjaamiseen
                        </p>
                    </div>
                    <div className="row">
                        {offer?.accepted == null ? (
                            <>
                                <button
                                    onClick={() => offerInteract(true)}
                                    className="btn btn-success row-button"
                                >
                                    Hyväksy
                                </button>
                                <button
                                    onClick={() => offerInteract(false)}
                                    className="btn btn-danger row-button"
                                >
                                    Hylkää
                                </button>
                            </>
                        ) : (
                            <>
                                <p className="row-text gray">Hyväksytty</p>
                                <p className="row-text orange">
                                    {offer?.accepted ? "Kyllä" : "Ei"}
                                </p>
                            </>
                        )}
                    </div>
                </div>
                {offer.otherServices.length > 0 && (
                    <div className="row-container">
                        <div className="row">
                            <p className="row-text">Tarjouksen lisätiedot</p>
                        </div>
                        <div className="row">
                            <p className="row-text gray">
                                Piha-alueen muut havaitut vauriot
                            </p>
                        </div>
                        <div className="list">
                            {offer.otherServices.map((service, index) => {
                                return (
                                    <div className="list-row" key={index}>
                                        <p className="orange">
                                            {serviceMap[service]}
                                        </p>
                                        <div
                                            className="img-row"
                                            style={{
                                                textAlign: "left",
                                                margin: "0",
                                            }}
                                        >
                                            <ImageLister
                                                images={
                                                    offer.otherServiceImages[
                                                        service
                                                    ]
                                                }
                                                size={50}
                                            />
                                        </div>
                                    </div>
                                );
                            })}
                        </div>
                        {offer?.otherContent && (
                            <>
                                <div className="row">
                                    <p className="row-text gray">
                                        Muiden palveluiden lisätiedot
                                    </p>
                                </div>

                                <div className="row row-small">
                                    <p className="row-text orange">
                                        {offer?.otherContent}
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        </>
    );
};

export default DetailsOfferInfo;
