import React, { useEffect, useContext, useState } from "react";
import {
    txCollectionPath,
    notificationCollectionPath,
    reviewCollectionPath,
} from "../CollectionPaths";
import DetailsBasicInfo from "./DetailsBasicInfo";
import DetailsOfferInfo from "./DetailsOfferInfo";
import DetailsWorkreportInfo from "./DetailsWorkreportInfo";
import DetailsReviewInfo from "./DetailsReviewInfo";
import { FirebaseContext } from "../firebase";
import ProgressVisualizer from "./ProgressVisualizer";
const queryString = require("query-string");
require("firebase/firestore");

const Details = () => {
    // get reference to firebase through the context
    const firebase = useContext(FirebaseContext);
    // get reference to firestore
    const db = firebase.firestore();

    // state for all the documents we use for "transactions"
    // they are stored as firebase references on the transaction document
    // e.g. transaction.offer would hold the reference for the offer document
    const [transaction, setTransaction] = useState();
    const [offer, setOffer] = useState();
    const [workReport, setWorkReport] = useState();
    const [review, setReview] = useState();
    const [initializing, setInitializing] = useState(true);
    const [errorText, setErrorText] = useState();

    const [newReview, setNewReview] = useState({
        score: 3,
        content: "",
        targetUser: "",
        timeStamp: Date.now(),
    });

    // collapses all other Details except the one configurd here
    // basic = "Perustiedot",
    // offer = "Tarjous",
    // report = "Työraportti",
    // review = "Palaute"
    const [focus, setFocus] = useState("basic");

    const [documentId, setDocumentId] = useState();
    //get the unique ID from current URL
    const parsed = queryString.parse(window.location.search);

    // make sure we have an ID in the URL, and that the ID has not been set already
    if (parsed && parsed.id) {
        if (!documentId) {
            setDocumentId(parsed.id);
        }
    } else {
        console.log("No ID in URL");
        if (!errorText) {
            setErrorText("Tilausta ei määritetty.");
        }
    }

    useEffect(() => {
        if (documentId) {
            if (!transaction) {
                const txRef = db
                    .collection(txCollectionPath)
                    .doc(documentId)
                    .get()
                    .then((documentSnapshot) => {
                        if (documentSnapshot.exists) {
                            const tx = documentSnapshot.data();
                            if (!transaction) {
                                console.log("updating transaction");
                                setTransaction(tx);
                            }
                        } else {
                            console.log("No document with id", documentId);
                            setErrorText(
                                "Tilausta " + documentId + " ei löydy."
                            );
                        }
                    });
            } else {
                console.log("Transaction exists, fetching stuff");
                if (transaction.offer && !offer) {
                    updateData(transaction.offer, "offer");
                } else if (transaction.workReport && !workReport) {
                    updateData(transaction.workReport, "workReport");
                } else if (transaction.review && !review) {
                    updateData(transaction.review, "review");
                } else {
                    console.log("nothing else to fetch!");
                    // initialization completed
                    if (initializing) {
                        setInitializing(false);
                    }
                }
            }
        }
    }, [documentId, transaction, offer, workReport, review]);

    const offerCallback = (newOffer) => {
        setOffer(newOffer);
    };

    const notificationCallback = (type, params) => {
        console.log("creating a notification from params", type, params);
        if (type === "offer") {
            createNotification(
                transaction.claimedBy,
                transaction.key,
                "Tarjous päivitetty",
                "Tarjous kohteessa " + transaction.refId + " " + params + ".",
                null
            );
        }
    };

    const createNotification = (target, transaction, title, content, time) => {
        db.collection(notificationCollectionPath)
            .doc()
            .set({
                target: target,
                transaction: transaction,
                title: title,
                content: content,
                time: time,
            })
            .then(() => console.log("Notification sent"))
            .catch((e) => console.log("Notification creation failed", e));
    };

    const onReviewCreated = () => {
        // create document for the review, and set some data
        const doc = db.collection(reviewCollectionPath).doc();
        const id = doc.id;
        newReview.key = id;
        newReview.targetUser = transaction.claimedBy;
        newReview.timeStamp = Date.now();

        // upload review
        db.collection(reviewCollectionPath)
            .doc(id)
            .set(newReview)
            .then(() => {
                console.log("Review upload success (" + newReview.key + ")");
                // update review to TX
                const newTx = { ...transaction };
                newTx.review = doc;
                db.collection(txCollectionPath)
                    .doc(newTx.key)
                    .set(newTx)
                    .then(() => {
                        console.log(
                            "Transaction updated successfully (" +
                                newTx.key +
                                ")"
                        );
                        // create notification
                        setTransaction(newTx);
                    });
            });

        console.log("Submitting review", newReview);
    };

    // handle offer, workReport & review references
    // and updates the state of those as well
    function updateData(reference, type) {
        console.log("fetching", type);
        reference.get().then((documentSnapshot) => {
            if (documentSnapshot.exists) {
                const data = documentSnapshot.data();
                if (type === "offer" && !offer) {
                    setFocus("offer");
                    setOffer(data);
                } else if (type === "workReport" && !workReport) {
                    setFocus("report");
                    setWorkReport(data);
                } else if (type === "review" && !review) {
                    setFocus("review");
                    setReview(data);
                } else {
                    console.log("unknown type or tried again", type);
                }
            }
        });
    }

    if (transaction) {
        return (
            <div className="container">
                <div className="info-block">
                    <p
                        className="orange"
                        style={{ fontSize: "1.5rem", marginBottom: "0" }}
                    >
                        {transaction?.locationName ?? "PH"}
                    </p>
                    <p style={{ fontWeight: "bold" }}>
                        Tilausnumero {transaction?.refId}
                    </p>
                    <ProgressVisualizer transaction={transaction} />
                </div>
                <div className="info-block">
                    <DetailsBasicInfo
                        transaction={transaction}
                        visible={focus === "basic"}
                    />
                </div>
                {offer && (
                    <div className="info-block">
                        <DetailsOfferInfo
                            offer={offer}
                            visible={focus === "offer"}
                            callback={offerCallback}
                            notificationCallback={notificationCallback}
                        />
                    </div>
                )}
                {workReport && (
                    <div className="info-block">
                        <DetailsWorkreportInfo
                            workReport={workReport}
                            visible={focus === "report"}
                        />
                    </div>
                )}
                {workReport && !review && (
                    <>
                        <div className="info-block">
                            <button
                                className="btn btn-orange"
                                data-toggle="modal"
                                data-target="#reviewModal"
                            >
                                Anna palautetta
                            </button>
                        </div>

                        <div
                            className="modal fade"
                            id="reviewModal"
                            tabIndex="-1"
                            role="dialog"
                            aria-labelledby="exampleModalCenterTitle"
                            aria-hidden="true"
                        >
                            <div
                                className="modal-dialog modal-dialog-centered"
                                role="document"
                            >
                                <div className="modal-content">
                                    <div className="modal-header">
                                        <h5
                                            className="modal-title"
                                            id="exampleModalLongTitle"
                                        >
                                            Palaute
                                        </h5>
                                        <button
                                            type="button"
                                            className="close"
                                            data-dismiss="modal"
                                            aria-label="Close"
                                        >
                                            <span aria-hidden="true">
                                                &times;
                                            </span>
                                        </button>
                                    </div>
                                    <div className="modal-body">
                                        <p>
                                            Arvioi työn laatu asteikolla 1 - 5
                                        </p>
                                        <div className="row">
                                            <input
                                                type="range"
                                                min="1"
                                                max="5"
                                                id="reviewRange"
                                                value={newReview.score}
                                                onChange={(e) => {
                                                    const rw = {
                                                        ...newReview,
                                                    };
                                                    rw.score = e.target.value;
                                                    setNewReview(rw);
                                                }}
                                            />

                                            <p>{newReview.score}</p>
                                        </div>
                                        <p style={{ marginTop: ".5rem" }}>
                                            Palaute
                                        </p>
                                        <textarea
                                            type="text"
                                            className="form-control"
                                            placeholder="Avoin palaute"
                                            value={newReview.content}
                                            onChange={(e) => {
                                                const rw = { ...newReview };
                                                rw.content = e.target.value;
                                                setNewReview(rw);
                                            }}
                                        />
                                    </div>
                                    <div className="modal-footer">
                                        <button
                                            type="button"
                                            className="btn btn-secondary"
                                            data-dismiss="modal"
                                        >
                                            Peruuta
                                        </button>
                                        <button
                                            type="button"
                                            className="btn btn-orange"
                                            onClick={onReviewCreated}
                                            data-dismiss="modal"
                                        >
                                            Lähetä
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                )}
                {review && (
                    <div className="info-block">
                        <DetailsReviewInfo
                            review={review}
                            visible={focus === "review"}
                        />
                    </div>
                )}
            </div>
        );
    } else {
        if (errorText) {
            return <p style={{ fontSize: "2rem" }}>{errorText}</p>;
        }
        return (
            <>
                <p>Ladataan...</p>
            </>
        );
    }
};

export default Details;
