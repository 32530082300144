import React from "react";
import ImageLister from "./ImageLister";

const DetailsWorkreportInfo = ({ workReport, visible }) => {
    //console.log("workReport", workReport);
    return (
        <>
            <button
                className="collapse-button"
                data-toggle="collapse"
                data-target="#report"
            >
                Työraportti
            </button>
            <div className={visible ? "collapse show" : "collapse"} id="report">
                <div className="row-container first">
                    <div className="row">
                        <p className="row-text">Korjauksen tiedot</p>
                    </div>
                    <div className="row row-small">
                        <p className="row-text gray">
                            {workReport.mainContent}
                        </p>
                    </div>
                    <div className="row">
                        <p className="row-text orange">Kuvat korjauksista</p>
                    </div>
                </div>
                <div className="img-row">
                    <ImageLister images={workReport?.imageReference} />
                </div>
            </div>
        </>
    );
};

export default DetailsWorkreportInfo;
