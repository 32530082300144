import React from "react";

import { FirebaseContext } from "../firebase";
import ImageLister from "./ImageLister";

const DetailsBasicInfo = ({ transaction, visible }) => {
    //console.log("tx", transaction);
    let mapsLink =
        "http://www.google.com/maps/search/" +
        transaction.address +
        "/@" +
        transaction.location.latitude +
        "," +
        transaction.location.longitude;

    return (
        <>
            <button
                className="collapse-button"
                data-toggle="collapse"
                data-target="#basic"
            >
                Perustiedot
            </button>
            <div className={visible ? "collapse show" : "collapse"} id="basic">
                <div className="row-container first">
                    <div className="row">
                        <p className="row-text gray">Tilaajan nimi</p>
                        <p className="row-text orange">
                            {transaction.reporterName}
                        </p>
                    </div>
                    <div className="row">
                        <p className="row-text gray">Puhelinnumero</p>
                        <p className="row-text orange">
                            {transaction.phoneNumber}
                        </p>
                    </div>
                    <div className="row">
                        <p className="row-text gray">Sähköposti</p>
                        <p className="row-text orange">{transaction.email}</p>
                    </div>
                </div>
                <div className="row-container">
                    <div className="row">
                        <p className="row-text">
                            {transaction.locationName
                                ? transaction.locationName
                                : "PH"}
                        </p>
                    </div>
                    <div className="row">
                        <p className="row-text gray">Osoite</p>
                        <p className="row-text orange">{transaction.address}</p>
                    </div>
                    <div className="row">
                        <p className="row-text gray">Sijainti</p>
                        <a href={mapsLink} className="row-text orange bold">
                            Katso kartalta
                        </a>
                    </div>
                    <div className="row">
                        <p className="row-text orange">Kuvat kohteesta</p>
                    </div>
                </div>
                <div className="img-row">
                    <ImageLister images={transaction.imageReference} />
                </div>
                {transaction.additionalInfo.length > 0 && (
                    <div
                        className="row-container"
                        style={{ marginTop: "2.5rem" }}
                    >
                        <div className="row">
                            <p className="row-text">Lisätiedot</p>
                        </div>
                        <div className="row row-small">
                            <p className="row-text orange">
                                {transaction.additionalInfo}
                            </p>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

export default DetailsBasicInfo;
