import React from "react";

import ProgressBar from "./ProgressBar";

const DetailsReviewInfo = ({ review, visible }) => {
    //console.log("review", review);
    // normalize score (1-5) between 0 and 1, then times 100
    const normalizedScore = ((review.score - 1) / (5 - 1)) * 100;
    return (
        <>
            <button
                className="collapse-button"
                data-toggle="collapse"
                data-target="#review"
            >
                Palaute
            </button>
            <div className={visible ? "collapse show" : "collapse"} id="review">
                <div className="row-container first">
                    <div className="row">
                        <p className="row-text">Työn laatu (1-5)</p>
                    </div>
                    <div className="row row-small">
                        <ProgressBar
                            completed={normalizedScore}
                            bgcolor={"#ff6200"}
                            score={review.score}
                        />
                    </div>
                    <div className="divider" />
                    <div className="row">
                        <p className="row-text">Avoin palaute</p>
                    </div>
                    <div className="row row-small">
                        <p className="row-text gray">{review?.content}</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default DetailsReviewInfo;
