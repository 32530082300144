import React from "react";

const ProgressBar = (props) => {
    const { bgcolor, completed } = props;

    const containerStyles = {
        height: 22,
        width: "100%",
        backgroundColor: "#e0e0de",
        borderRadius: 5,
    };

    const fillerStyles = {
        height: "100%",
        width: `${completed}%`,
        backgroundColor: bgcolor,
        borderRadius: "inherit",
        textAlign: "right",
    };

    const labelStyles = {
        padding: 5,
        color: "white",
        fontWeight: "bold",
        height: "100%",
        fontSize: "16px",
    };

    return (
        <div style={containerStyles}>
            <div style={fillerStyles}>
                <span style={labelStyles}>
                    <span>{props.score}</span>
                </span>
            </div>
        </div>
    );
};

export default ProgressBar;
